import React, { useEffect } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import { IoMdClose } from "react-icons/io";
import { mainWhite, screen } from "../common/variables";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  .close-icon {
    color: ${mainWhite};
    cursor: pointer;
    position: absolute;
    top: 18px;
    right: 18px;
    font-size: 2.3rem;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }

  .player-center {
    max-width: 1250px;
    width: 100%;
  }

  .player-container {
    position: relative;
    padding-top: 56.25%;

    &__player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      iframe {
        border: 5px ${mainWhite} solid !important;
        @media ${screen.small} {
          border: 10px ${mainWhite} solid !important;
        }
      }
    }
  }
`;

const VideoModal = ({ url, closeVidModal }) => {
  useEffect(() => {
    const userClick = e => {
      if (e.target.id === "video-modal-container") closeVidModal();
    };
    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);

  return (
    <Wrapper id="video-modal-container">
      <IoMdClose className="close-icon" onClick={closeVidModal} />
      <div className="player-center">
        <div className="player-container">
          <ReactPlayer
            className="player-container__player"
            url={url}
            controls={true}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default VideoModal;
