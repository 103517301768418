import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import {
  mainWhite,
  mainOrange,
  darkBlue,
  montserratRegular,
  ccbiffbamboomRegular,
  screen,
  transHover,
} from "../common/variables";
import { GatsbyImage } from "gatsby-plugin-image";
import { ScrollLocky } from "react-scroll-locky";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;
const Wrapper = styled.div`
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: ${fadeIn} 0.35s forwards;

  .close-btn {
    color: ${mainWhite};
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 2.3rem;
    transition: ${transHover};
    font-family: ${ccbiffbamboomRegular};
    font-size: 1.47rem;
    margin: 0;
    @media ${screen.small} {
      font-size: 1.7rem;
    }
    &:hover {
      opacity: 0.65;
    }
  }

  .container {
    position: relative;
    max-width: 1430px;
    width: calc(100% - 50px);
    padding: 80px 25px 70px 25px;
    margin: 0 auto;
    height: calc(100vh - 50px);
    overflow: auto;
    @media ${screen.small} {
      height: auto;
      padding: 120px 75px 120px 75px;
    }
    @media ${screen.medium} {
      padding: 120px 160px 120px 100px;
    }
    @media ${screen.xlarge} {
      width: 100%;
    }

    .flex {
      display: flex;
      flex-direction: column;
      @media ${screen.medium} {
        flex-direction: row;
        align-items: center;
      }

      .col {
        &--left {
          @media ${screen.small} {
            padding: 0 120px 0 0;
          }
          @media ${screen.medium} {
            width: 37%;
          }

          .img {
            transform: rotate(-7deg);
            max-width: 200px;
            @media ${screen.small} {
              max-width: 470px;
            }
            @media ${screen.medium} {
              max-width: none;
            }
          }

          .name {
            font-family: ${ccbiffbamboomRegular};
            font-size: 1.7rem;
            margin: 25px 0 0 0;
            @media ${screen.small} {
              font-size: 2.27rem;
              margin: 52px 0 0 0;
            }
            @media ${screen.medium} {
              margin: 32px 0 0 0;
            }
          }
        }

        &--right {
          @media ${screen.medium} {
            width: 63%;
          }

          .description {
            color: ${mainWhite};
            font-family: ${montserratRegular};
            margin: 30px 0 0 0;
            @media ${screen.medium} {
              margin: 0;
            }

            p {
              font-size: 1.22rem;
              margin: 0 0 21px 0;

              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
    }

    &--blue {
      background: ${darkBlue};

      .name {
        color: ${mainOrange};
      }

      .excerpt {
        color: ${mainWhite};
        font-family: ${montserratRegular};
        font-size: 1.22rem;
        margin: 4px 0 0 0;
        font-weight: 600;
        font-style: italic;
      }
    }

    &--orange {
      background: ${mainOrange};

      .name {
        color: ${darkBlue};
      }

      .excerpt {
        display: none;
      }
    }
  }
`;

const PersonModal = ({ person, setPerson }) => {
  useEffect(() => {
    const userClick = e => {
      if (e.target.id === "person-modal-container") setPerson(null);
    };
    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);

  return (
    <Wrapper id="person-modal-container">
      <ScrollLocky isolation={false} enabled={person}>
        <div
          className={
            person.theme === "Blue"
              ? "container container--blue"
              : "container container--orange"
          }
        >
          <div className="flex">
            <div className="col col--left">
              <div className="img">
                <GatsbyImage image={person.image} alt="Bio" />
              </div>
              <h4 className="name">{person.name}</h4>
              <p className="excerpt">{person.excerpt}</p>
            </div>
            <div className="col col--right">
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: person.description.html }}
              />
            </div>
          </div>

          <span
            className="close-btn"
            onClick={() => {
              setPerson(null);
            }}
          >
            CLOSE
          </span>
        </div>
      </ScrollLocky>
    </Wrapper>
  );
};

export default PersonModal;
